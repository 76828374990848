import styled from "@emotion/styled";
import {colors} from "../index";

export const Container = styled.div`
    position:relative;
    flex:${props => props.flex};
    max-width:500px;
    width:100%;
    margin:0 auto 24pt;
    
    label{
        color:${props=> props.theme.label.color};
        font-weight:${props=> props.theme.label.weight};
        text-align:left;
        width:100%;
        margin-bottom:0;
        line-height:1.3em;
        font-family:'ek_muktabold', sans-serif,Helvetica;
        font-size: 17px;
        
        @media all and (max-width:340px){
            font-size: 14px; 
        }
     }
    
    input,select,textarea{
        width:100%;
        background-color: transparent;
        color:  ${props=> props.theme.color};
        cursor: ${props=> props.theme.cursor}; 
        height: 25pt;
        padding:${props=> props.theme.input.padding};
        border-bottom: ${props=> props.theme.input.border};
        font-weight:${props=> props.theme.input.weight};
        font-size: 12pt;
        
        &:focus{
            outline:none;
            border-bottom:${props=> props.theme.focus.border};   
        }
        
        @media all and (min-width:768px){
            font-size:${props=> props.theme.input.fZise};
        }  
    }
    
    .bfr{
        font-size:22px;
        width:30px;
        height:100%;
        color:${props=> props.theme.focus.color};
        
        @media all and (min-width:768px){
            font-size:${props=> props.theme.input.fZise};
        }  
    }
    
    .before{
        font-size:22px;
    }
    
    textarea{
        height:70px;
    }
    
    .error-x{
        position:absolute;
        right:0;
        width:18pt;
        height:100%;
        color:${props=> props.theme.caret}!important;
        bottom:0;
    }
    
    .error{
        font-family:'ek_muktaextrabold', sans-serif,Helvetica;
        color:${colors.error};
        font-size:11pt;
    }
`;
