import React, {useEffect, useState} from "react";
import {CSSTransition} from "react-transition-group";

function Fade({children}) {

    let [entra,SetEntra] = useState(false);

    useEffect(()=>{
        SetEntra(true);
    },[]);

    return (
        <CSSTransition
            in={entra}
            timeout={600}
            classNames="fade"
            unmountOnExit
        >
            {children}
        </CSSTransition>
    )
}

export default React.memo(Fade);
