import styled from "@emotion/styled";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as Yup from "yup";
import { colors } from "../../UI";
import Fade from "../../UI/Fade";
import Flex from "../../UI/Flex";
import ShowModal from "../../UI/modal/ShowModal";
import { getComision, processTrx, setCookie } from "../../helper";
import Btn from "./../../UI/Btn";
import Field from "./../../UI/field";
import Loading from "./../../UI/loading";

const Container = styled.div`
  position: relative;

  padding: 15px 25px;

  .title {
    color: ${colors.greem};
    font-size: 24px;
    text-align: center;
    max-width: 300px;
    margin: auto;
    line-height: 1.2em;
    margin-bottom: 21pt;
    margin-top: 24px;
  }
`;

function Recargar(props) {
  let idRequest = props.history.location.search;
  idRequest = new URLSearchParams(idRequest).get("recharge");
  setCookie("idRequest", idRequest);

  const [state, setState] = useState({
    loading: false,
    bancos: [],
  });

  const [modal, SetM] = useState({
    commission: 0,
    modal: false,
    loading: false,
  });

  const [data, setData] = useState({
    number: "",
    confirmNumber: "",
    amount: "",
    bank: "",
    idRequest: "",
  });

  const handlerModal = (x) => SetM({ ...modal, ...x });

  const RecargarSchema = Yup.object().shape({
    number: Yup.string()
      .matches(/^(3[0|1|2|3|5])[0-9]{8,}$/, "El número no es válido.")
      .required("Debes ingresar un número de Cívica."),
    confirmNumber: Yup.string()
      .oneOf([Yup.ref("number"), null], "Los datos ingresados son incorrectos.")
      .required("Debes confirmar el número."),
    amount: Yup.number()
      .min(1000, "Monto no válido (min. $1,000 COP).")
      .max(500000, "Monto no válido (max. $500.000 COP)")
      .required("Debes ingresar un valor."),
    bank: Yup.string().required("Debes seleccionar un banco."),
  });

  useEffect(() => {
    setData({ ...data, idRequest });
    setCookie("confirm", "false");
  }, []);

  return (
    <Container className={""}>
      <div className="content">
        {props.global.loading ? (
          <Loading only noBg />
        ) : (
          <Fade enter={!state.loading}>
            <Formik
              initialValues={data}
              validationSchema={RecargarSchema}
              onSubmit={async (values) => {
                setState({ ...state, procesando: true });
                //OBTEN LA COMISIÓN
                let commission = await getComision("1054", values.amount);

                if (commission.status) {
                  setState({ ...state, procesando: false });
                  setData({
                    ...data,
                    number: values.number,
                    amount: values.amount,
                    bank: values.bank,
                  });
                  handlerModal({
                    modal: true,
                    commission: commission.commission,
                  });
                } else {
                  if (commission.limitTransaction < data.amount) {
                    props.setToast("ERROR: intenta más tarde.", true);
                  } else {
                    props.setToast(
                      `Monto no válido (max. $${commission.limitTransaction} COP)`,
                      true
                    );
                  }
                  setState({ ...state, procesando: false });
                }
              }}
            >
              {({ values, setFieldValue, errors, touched, handleSubmit }) => (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <div className="wc title">
                    <p className={"mb-0 ek-Xbold"}>
                      Completa los datos para recargar Cívica Pay
                    </p>
                  </div>
                  <div className={"wc"}>
                    <Field
                      label={"Ingresa el número Cívica Pay"}
                      placeholder={"Número celular registrado en Cívica Pay"}
                      type={"tel"}
                      onChange={(e) => {
                        let value = e.target.value;
                        value = value.replace(/[^0-9]/g, "");
                        value = value.substring(0, 10);
                        setFieldValue("number", value);
                      }}
                      value={values.number}
                      error={errors.number && touched.number && errors.number}
                      disabled={state.procesando}
                    />
                    <Field
                      label={"Confirma el número Cívica Pay"}
                      placeholder={"Confirmar número"}
                      type={"tel"}
                      onChange={(e) => {
                        let value = e.target.value;
                        value = value.replace(/[^0-9]/g, "");
                        value = value.substring(0, 10);
                        setFieldValue("confirmNumber", value);
                      }}
                      value={values.confirmNumber}
                      error={touched.confirmNumber && errors.confirmNumber}
                      disabled={state.procesando}
                    />

                    <Field
                      label={"Ingresa el valor de tu recarga"}
                      type={"tel"}
                      onChange={(e) => {
                        let value = e.target.rawValue;
                        value = value.replace(/[^0-9]/g, "");
                        setFieldValue("amount", value);
                      }}
                      value={values.amount}
                      error={touched.amount && errors.amount}
                      before
                      placeholder={"Valor mínimo de recarga $ 1.000"}
                      disabled={state.procesando}
                      Clve
                      ClveOptions={{
                        numeral: true,
                        signBeforePrefix: true,
                        rawValueTrimPrefix: true,
                        delimiter: ",",
                      }}
                    />

                    <Field
                      label={"¿Dónde tienes tu dinero?"}
                      error={touched.bank && errors.bank}
                      select
                      disabled={state.procesando}
                    >
                      <select
                        value={values.bank}
                        onChange={(e) => {
                          setFieldValue("bank", e.target.value);
                        }}
                        disabled={state.procesando}
                      >
                        <option value="" disabled>
                          {" "}
                          Selecciona el banco
                        </option>
                        {props.global.banks.length > 0 &&
                          props.global.banks.map((item, key) => {
                            if (item.bankCode !== "0") {
                              return (
                                <option key={key} value={item.bankCode}>
                                  {item.bankName}
                                </option>
                              );
                            }
                          })}
                      </select>
                    </Field>
                    <div className={"wc text-center pb-4"}>
                      <Btn
                        mw={"169pt"}
                        className={"mx-auto"}
                        btntype={"submit"}
                        disabled={
                          Object.values(errors)[0] &&
                          Object.values(touched).length === 4
                        }
                        loading={state.procesando}
                      >
                        Continuar
                      </Btn>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </Fade>
        )}
      </div>

      <ShowModal
        show={modal.modal}
        confirmText={"Aceptar"}
        hideClose
        cancel={() => handlerModal({ modal: false })}
        onConfirm={async () => {
          handlerModal({ loading: true });
          const result = await processTrx(data);
          if (result.status) {
            window.location.assign(result.url);
          } else {
            props.setToast(result.message, true);
            handlerModal({ loading: false, modal: false });
          }
        }}
        loading={modal.loading}
      >
        <Flex className={"wc"} alg={"flex-start"} jc={"flex-start"}>
          <Flex className={"text-center"} flex={"1 0 60%"}>
            <div className={"col-12 text-center pt-4"}>
              <p style={{ fontSize: "18px" }} className={"mb-0"}>
                Esta transacción tiene un costo de ${modal.commission}
                <br />
                ¿Deseas continuar?
              </p>
            </div>
          </Flex>
        </Flex>
      </ShowModal>
    </Container>
  );
}

const MSTrpops = (state) => ({ global: state.global });

export default connect(MSTrpops)(withRouter(React.memo(Recargar)));
