import React, {Component, useEffect} from "react";
import styled from "@emotion/styled";
import { CSSTransition } from 'react-transition-group';
import "./styles.scss";
import {colors} from "../index";
import Flex from "./../Flex";
import Icon from "./alerta.png";
import ReactDOM from "react-dom";


const Container = styled.div`
    width:100%;
    position:fixed;
    max-width:560px;
    top:0;
    left:50%;
    transform:translateX(-50%);
    background:${props=>props.bg};
    color:${props=>props.color};
    z-index:999999;
    border-radius: 0 0 10px 10px;
    box-shadow:0 1px 5px rgba(0,0,0,.2);
       
    
    .content{   
        font-size:13px;
        font-family:'Roboto';
        font-weight:300;
    }
    
    .icon{
    color:${colors.rojo01};
    font-size:18px;
    }
    
    @media all and (min-width:600px){
        top:15px;
        border-radius: 12px;
        font-size:18px;
    }    
`;

function TheToast(props) {

    useEffect(()=>{
        setTimeout(()=>{
            props.close(null,false)
        },6000)
    },[])

    return (
        <CSSTransition
            in={props.status}
            timeout={300}
            classNames="alert"
            unmountOnExit
        >
            <Container bg={props.blanco ? "#fff" : "#000"} color={props.blanco ? "#000" : "#fff" }>
                <div className="col-12 p-3 px-xl-4  content">
                    <Flex className={"px-0 col-12 col-xl-10 mx-auto"} >
                        <Flex flex={"20px"}>
                            <img src={Icon} alt="" height={"25px"} width={"auto"}/>
                        </Flex>
                        <Flex flex={"1 0 70%"} jc={"flex-start"} className={"mensaje"}>
                            {props.message}
                        </Flex>
                    </Flex>
                </div>
            </Container>
        </CSSTransition>
    )
}


const contToast = document.getElementById('cont-modal');

class Toast extends Component {
    constructor(props){
        super(props);
        this.el = document.createElement('div')
    }
    componentDidMount() {
        contToast.appendChild(this.el)
    }

    render() {
        // React does *not* create a new div. It renders the children into `domNode`.
        // `domNode` is any valid DOM node, regardless of its location in the DOM.
        return ReactDOM.createPortal(
            <TheToast {...this.props} />,
            this.el
        );
    }
}

export default React.memo(Toast);
