import React, {useEffect, useState} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import Recargar from "./componentes/recargar";
import Response from "./componentes/response";
import {getToken} from "./helper";
import Toast from "./UI/toast";
import {connect} from "react-redux";
import store from "./store";
import {SET_STATE_GLOBAL} from "./store/actions";


function App(props) {


    let [toast, SetToast] = useState({
        status: false || props.global.toast.status,
        message: '' || props.global.toast.message
    });

    let setToast = (mensaje, status = true) => {
        SetToast({
            ...toast,
            status: status,
            message: mensaje
        });

        store.dispatch({
            type: SET_STATE_GLOBAL,
            payload: {
                toast: {
                    status: false,
                    message: ""
                }
            }
        });
    };

    useEffect(() => {
        getToken(true);
    }, []);

    return (
        <div className={"main-container"}>
            <Router>
                <React.Fragment>
                    <Switch>
                        <Route exact path="/" render={() => <Recargar setToast={setToast}/>}/>
                        <Route exact path="/respuesta" render={() => <Response setToast={setToast}/>}/>
                    </Switch>
                </React.Fragment>
            </Router>
            <Toast blanco status={toast.status || props.global.toast.status}
                   message={toast.message || props.global.toast.message} close={setToast}/>
        </div>
    );
}

const MSTrpops = state => ({global: state.global});

export default connect(MSTrpops)(React.memo(App));
