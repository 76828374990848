import React,{memo} from "react";
import {Document, Page, View, Image, Text, StyleSheet, Font, PDFDownloadLink} from "@react-pdf/renderer";
import styled from "@react-pdf/styled-components";
import {colors} from "../../UI";
import {numberWithCommas} from "../../helper";
import moment from "moment";
import {BtnIcon} from "../response/helper";

const Ccontainer = styled.View`
    padding:10pt 40pt 20pt;
    width:100%;
    margin:auto;
    height:100%;
    font-family: 'rRegular';
    justify-content:flex-start;
`;

const Flex = styled.View`
    width:100%;
    display:flex;
    flex-flow:${props => props.direction ? props.direction : "row"} wrap;
    justify-content: ${props => props.jc};
    align-items:center;
    flex:${props => props.flex};
    align-content:flex-start;
`;

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
    },
    image:{
        flex:"0 0 300px",
        width:"20%"
    },
    image2:{
        flex:"0 0 400px",
        width:"25%"
    },
    title:{
        textAlign:"center",
        fontSize:"16pt",
        padding:"20pt 0 10pt"
    },
    titleR:{
        textAlign:"center",
        color:colors.rojoD,
        padding:"20pt 0 10pt"
    },
    tabla:{
        borderTop:`2pt solid ${colors.rojoD}`,
        borderBottom:`2pt solid ${colors.rojoD}`,
        padding:"10px 0"
    },
    itemNV:{
        fontSize:"12pt",
        padding:"5pt 0"
    },
    itemHijos:{
        fontWeight:"bold"
    },
    textFooter:{
        fontSize: "7pt",
        textAlign: "center"
    },
    vigilado:{
        position:"absolute",
        left:"15pt",
        bottom:"100pt",
        width:"50pt"
    },
    VigiladoImg:{
        width:"15pt",
        height:"auto"
    },
    bold:{
        fontFamily:"rBold"
    },
    small:{
        fontSize:"12pt"
    },
    textRight:{
        position:"absolute",
        right:"-50pt",
        top:"100pt",
        transform:"rotate(-90deg)",
        fontSize:"8pt"
    }
});


Font.register({
    family: 'rBold',
    src: 'https://multimedia-epayco.s3.amazonaws.com/PDF/Fonts/Roboto_Condense/RobotoCondensed-Bold.ttf',
});

Font.register({
    family: 'rRegular',
    src: 'https://multimedia-epayco.s3.amazonaws.com/PDF/Fonts/Roboto_Condense/RobotoCondensed-Regular.ttf',
});

const ItemNV = memo(({name,value})=>{
    return(
        <Flex jc={"center"} flex={"1 0 auto"} style={styles.itemNV}>
            <Flex jc={"flex-start"} flex={"1 0 200px"} style={styles.bold}>
                <Text>{name}</Text>
            </Flex>
            <Flex jc={"flex-end"} flex={"1 0 100px"}>
                <Text>{value}</Text>
            </Flex>
        </Flex>
    )
});


export const PDF = memo(({
                        monto,
                        numero,
                        referencia,
                        fecha,
                        costo,
                        transaccion,
                        cus,
                        motivo,
                        nAprovacion,
                        direcionIp,
                    })=>
    <Document>
        <Page size="A4">
            <Ccontainer>
                <Flex flex={"0 0 150px"} jc={"space-between"}>
                    <View style={styles.image2} >
                        <Image src="https://multimedia-epayco.s3.amazonaws.com/Brand/daviplata/civica.png" />
                    </View>
                </Flex>
                <Flex flex={"0 0 60px"}>
                    <View style={styles.title}>
                        <Text style={styles.bold}>
                            DAVIPLATA
                        </Text>
                        <Text>
                            ****{ numero && numero.substr(numero.length - 4)}
                        </Text>
                    </View>
                </Flex>

                <Flex jc={"flex-end"} flex={"0 0 50px"}>
                    <Text style={styles.small}>Fecha transacción: {fecha}</Text>
                </Flex>

                <Flex flex={"0 0 40px"} style={styles.bold}>
                    <Text style={styles.titleR}>ABONO PSE DAVIPLATA</Text>
                </Flex>
                <Flex jc={"flex-start"} direction={"column"}>
                    <Flex style={styles.tabla} flex={"0 0 auto"}>
                        <ItemNV name={"Resultado de la transacción:"} value={transaccion}/>
                        <ItemNV name={"Código único CUS:"} value={cus}/>
                        <ItemNV name={"Civica:"} value={numero}/>
                        <ItemNV name={"Motivo"} value={motivo}/>
                        <ItemNV name={"No. aprobación"} value={nAprovacion}/>
                        <ItemNV name={"Dirección IP:"} value={direcionIp}/>
                        <ItemNV name={"Valor transacción:"} value={monto}/>
                        <ItemNV name={"Referencia:"} value={referencia}/>
                    </Flex>
                </Flex>

                <Flex flex={"0 0 auto"}>
                    <Text style={styles.textFooter}>
                        DaviPlata es un depósito de dinero electrónico amparado por el seguro de depósito Fogafín. Consulte condiciones, reglamento, tarifas y más información en: www.daviplata.com.Para cualquier diferencia con el saldo, comuníquese con XXXXXX. Si requiere, puede comunicarse con nuestro Defensor del Consumidor Financiero: Carlos Mario Serna, Calle 72 No. 6-30, piso 18, Bogotá, teléfono 609-2013, fax 482-9715, correo electrónico: defensordelcliente@davivienda.com.
                    </Text>
                    <Text style={styles.textFooter}>
                        Revisoria fiscal: KPMG Ltda. Apartado 77859,Bogotá
                    </Text>
                </Flex>

                <View style={styles.vigilado}>
                    <Image src="https://multimedia-epayco.s3.amazonaws.com/PDF/vigilado.png"  style={styles.VigiladoImg}/>
                </View>

                <View style={styles.textRight}>
                    <Text>
                        Banco Davivienda S.A. NIT. 860.034.313-7
                    </Text>
                </View>
            </Ccontainer>
        </Page>
    </Document>)
;


export const ThePdf = React.memo(({response})=>

    <PDFDownloadLink
        document={
            <PDF
                monto={"$" + (response.amount && numberWithCommas(response.amount.toString())) + " COP" || "Sin información."}
                numero={response.number || "Sin información."}
                referencia={response.refPayco || "Sin información."}
                fecha={moment(response.date).format("DD/MM/YYYY") || "Sin información."}
                costo={response.comision || "Sin información."}
                transaccion={response.state || "Sin información."}
                cus={response.cus || "Sin información."}
                motivo={response.description || "Sin información."}
                nAprovacion={response.trxId || "Sin información."}
                direcionIp={response.ip || "Sin información."}
            />
        }
        fileName="Informacion_de_pago.pdf"
    >
        {({blob, url, loading, error}) => (loading ?
                <div className={"py-2 "} style={{flex:"0 0 48%"}}>
                    <p className={"mb-0 ek-bold"}>Procesando...</p>
                </div>
                :
                <div className={"wc"}>
                    <BtnIcon
                        flex={"0 0 160px"}
                        name={"Descargar"}
                        icon={"download"}
                        action={() => null}
                    />
                </div>
        )}
    </PDFDownloadLink>
)
