import Flex from "../../UI/Flex";
import React from "react";

export let ItemData = ({name="Sin información.",value="Sin información."})=>{
    return(
        <Flex className={"wc mb-3"} alg={"flex-start"}>
            <Flex flex={"1 0 48%"} jc={"flex-start"}>
                {name}
            </Flex>
            <Flex className={"ek-Xbold"} flex={"1 0 48%"} jc={"flex-end"} style={{color:"#5C5C5C"}}>
                {value}
            </Flex>
        </Flex>
    )
};

export let BtnIcon = ({icon,name,action,flex})=>{
    return(
        <Flex onClick={()=> action()} className={"p-2 "} flex={flex}>
            <Flex flex={"0 0 35px"} jc={"flex-start"} className={"pr-3"}>
                <i className={`icon-${icon}`}> </i>
            </Flex>
            <Flex flex={"0 0 60%"} jc={"flex-start"} className={"ek-bold"} style={{color:"#5C5C5C"}}>
                {name}
            </Flex>
        </Flex>
    )
};

