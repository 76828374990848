import axios from 'axios';
import store from "./store";
import {SET_STATE_GLOBAL} from "./store/actions";
import CryptoJS from "crypto-js";

const secretKeyEncript = process.env.REACT_APP_SECRET_KEY_ENCRIPT

export const encryptData = (value) => {
    return CryptoJS.AES.encrypt(value, secretKeyEncript).toString();
}

//ejemplo para insertar cookie
export const setCookie = (cname, value) => {
    let cookieMaxAge = 30
    let d = new Date()
    d.setTime(d.getTime() + cookieMaxAge * 24 * 60 * 60 * 1000)
    let expiration = 'expires=' + d.toUTCString()

    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(value), secretKeyEncript).toString();

    document.cookie = cname + '=' + ciphertext + ';' + expiration + ';path=/'
}

//ejemplo para obtener

export const getCookie = (cname) => {
    let name = cname + '='
    let ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
            const bytes = CryptoJS.AES.decrypt(c.substring(name.length, c.length), secretKeyEncript);

            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
        }
    }
    return ''
}

export const removeCookies = () => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getToken = async (banks = false) => {
    let token = "";
    await axios({
        method: 'post',
        data: {
            dominio: process.env.REACT_APP_DOMINIO_CONFIGURADO
        },
        url: process.env.REACT_APP_BASE_URL_REST_RECAUDO + "/api/recaudo/get/token"
    })
        .then(async result => {
            if (result.status === 200) {
                token = result.data.data.token;
                setCookie("token", token)
                if(banks){
                    GetTBanks(token);
                }
            }
        }).catch(error => {
            if(banks){
                GetTBanks();
            }
        });

    return token
};

// OBTENER LOS BANCOS
export let GetTBanks = async (token = false) => {
    //bankCode
    const disableBankCodes = [
        1551, "1551"//DAVIPLATA
    ]
    if(!token){
        token = await getToken();
    }
    const url = `${process.env.REACT_APP_BASE_URL_REST_RECAUDO}/api/apify/payment/process/pse`;
    axios({
        url: url,
        method: "get",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        }
    }).then(response => {
        const {data} = response;
        if (data.success) {
            const banks = data.data.filter(bank => !disableBankCodes.includes(bank.bankCode));
            store.dispatch({
                type: SET_STATE_GLOBAL,
                payload: {
                    banks,
                    loading: false
                },
            });
        } else {
            store.dispatch({
                type: SET_STATE_GLOBAL,
                payload: {
                    loading: false,
                    toast: {
                        status: true,
                        message: "Ocurrió un error bancos PSE."
                    }
                }
            });
        }
    })
        .catch((error) => {
            store.dispatch({
                type: SET_STATE_GLOBAL,
                payload: {
                    loading: false,
                    toast: {
                        status: true,
                        message: "Ocurrió un error bancos PSE." + error.message
                    }
                }
            });
        });
};

// OBTENER LA COMISION
export const getComision = async (number, amount) => {

    let Response = {};

    let token = await getToken();

    await axios({
        data: {"commission": number},
        url: ` ${process.env.REACT_APP_BASE_URL_REST_RECAUDO}/api/apify/commission/list`,
        method: "post",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        }
    })
        .then(result => {
            const {data} = result;
            setCookie("commission", 0);
            setCookie("canal", "");

            if (result.status === 200 && data.success) {
                let {
                    fixed,
                    percent,
                    limitTransaction,
                    description
                } = data.data;
                let commission = fixed ? fixed : percent;

                Response.commission = commission;
                Response.limitTransaction = limitTransaction;

                //  VALIDA EL MONTO QUE QUEIRE RECARGAR
                setCookie("commission", commission);
                setCookie("canal", description);

                //  VALIDA SI VIENE UNA COMISION
                if (amount && amount > limitTransaction) {
                    Response.status = false;
                    Response.typeError = "monto";
                    return Response;

                }
                Response.status = true;
                return Response;

            } else {
                Response.status = true;
                Response.typeError = "monto";
                Response.limitTransaction = 0;
                Response.message = "No se pudo consultar la comision.";
                return Response;
            }
        })
        .catch((e) => {
            Response.limitTransaction = 0;
            Response.status = true;
            Response.message = `No se pudo consultar la comision.${e}`;
            return Response;
        });

    return Response;
};

export const processTrx = async (data) => {

    let dominio = window.location.origin;
    let token = await getToken();

    const consulta =
        [
            {"parametro": "daviplataCellphone", "value": encryptData(data.number)},
            {"parametro": "value", "value": encryptData(data.amount)},
            {"parametro": "bank", "value": encryptData(data.bank)},
            {"parametro": "urlResponse", "value": encryptData(`${dominio}/respuesta`)},
            {"parametro": "idRequest", "value": encryptData(data.idRequest)},
            {"parametro": "commission", "value": encryptData("1054")},
            {"parametro": "email", "value": encryptData("")}
        ];

    let Obj = {};

    await axios({
        data: {consulta, dominio: process.env.REACT_APP_DOMINIO_RECARGAR},
        url: ` ${process.env.REACT_APP_BASE_URL_REST_RECAUDO}/api/recaudo/proyecto/api/consulta/facturas`,
        method: "post",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        }
    }).then(result => {
        const {text_response} = result.data;
        if (result.status === 200 && result.data.success) {
            const {facturas} = result.data.data;
            setCookie("refPayco", facturas[0].identificacionEmpresa);
            setCookie("transactionID", facturas[0].extra8);
            setCookie("number", data.number);

            Obj = {
                status: true,
                url: facturas[0].extra1,
                message: text_response
            }
        } else {
            Obj = {
                status: false,
                message: text_response
            }
        }
    }).catch(error => {
        Obj = {
            status: false,
            message: error.message
        }
    });

    return Obj;
}

