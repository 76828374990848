import React, {Component} from "react";
import ReactDOM from "react-dom";
import {CSSTransition} from 'react-transition-group';
import Flex from "../Flex";
import Btn from "../Btn";

const Modal = props => {

    let small = window.innerWidth < 768;

    return (

        <CSSTransition
            in={props.show}
            timeout={600}
            classNames="fade"
            unmountOnExit
        >
            <Flex className={"modal-container"} direction={"column"}>

                <div className={`sombra-modal ${props.masOscuro && "masOscuro"}`}> </div>

                <Flex className="area wc position-relative px-2 ">
                    {!props.reset ?
                    <div className={`card ${props.className} wc`} style={{maxWidth:props.MW  ? props.MW : "502px" }}>
                        {!props.hideClose &&
                        <div className="close close2"
                             onClick={props.onClose}
                        >
                            <i className={"icon-cancel"}> </i>
                            {/*<img src="/img/iconos/close.svg" alt="" className={"imgr"}/>*/}
                        </div>
                        }
                        <div className="content ">
                            {props.children}
                        </div>

                        {!props.noBtn &&
                            <Flex className={"wc botones p-3"} jc={"space-around"}>
                                <Btn size={small  ? "md" : "lg"}
                                     bRadius={"22px"} mw={ small ? "114px" :"180px"}
                                     className={"mx-2"}
                                     btntype={"line"}
                                     onClick={props.cancel}
                                     disabled={props.loading}
                                >
                                    Cancelar
                                </Btn>
                                <Btn
                                    size={small  ? "md" : "lg"}
                                    bRadius={"22px"} mw={ small ? "114px" :"180px"}
                                    className={"mx-2"}
                                    onClick={props.onConfirm}
                                    loading={props.loading}
                                >
                                    {props.confirmText ? props.confirmText : "Continuar"}
                                </Btn>
                            </Flex>
                        }
                    </div>
                        :
                        <div className={` wc gira`}>
                            {props.children}
                        </div>
                    }
                </Flex>

            </Flex>
        </CSSTransition>

    )
};

const modalRoot = document.getElementById('cont-modal');

class ShowModal extends Component {

    constructor(props){
        super(props);

        this.el = document.createElement('div')
    }

    componentDidMount() {

        modalRoot.appendChild(this.el)
    }

    render() {
        // React does *not* create a new div. It renders the children into `domNode`.
        // `domNode` is any valid DOM node, regardless of its location in the DOM.


        return ReactDOM.createPortal(
            <Modal {...this.props} >
                {this.props.children}
            </Modal>,
           this.el
        );
    }

}

export default React.memo(ShowModal);



