import {
    SET_STATE_GLOBAL
} from "../actions";

const initialState = {
    toast: {
        status: false,
        message: ""
    },
    banks: [],
    loading:true,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_STATE_GLOBAL:
            return {...state, ...action.payload};
        default:
            return state
    }
}
