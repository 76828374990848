import React from "react";
import {colors} from "../index";
import Flex from "./../Flex";
import Cleave from 'cleave.js/react';
import {Collapse} from "react-collapse";
import {Container} from "./styles";


function Field({
                flex="1 0 100%",
                children,
                disabled,
                error,
                label,
                type = "text",
                onChange,
                value,
                name,
                placeholder,
                style,
                mw,
                className,
                line,
                before,
                labelSize,
                inputHeight,
                font,
                weight,
                ClveOptions,
                Clve,
                fZise,
                 select
}) {

    const theme = {
        bg     : error    ? (line ? "transparent" : colors.errorbg )    :  (line ? "transparent" : colors.grey01),
        color  : error    ? colors.rojo01  : disabled ? "rgba(64,64,63,.5)" : '#000000',
        cursor : disabled ? "not-allowed"   : "pointer",
        borderRadius : line ? "0" : "7px",
        label  : {
            margin: line ? "3px" : "5px",
            color  : colors.label,
            weight : line ? (weight ? weight :"regular") : "bold",
            size   : "18pt",
            focus  : {
              bColor : colors
            },
            font    : font ? font : 'Roboto Condensed',
        },
        input:{
            border  : `2px solid ${error ? colors.error : colors.greem}`,
            height  : line ? (inputHeight ? inputHeight : "26px") : "42px",
            padding : error ? "0 18pt 0 0 " : "0",
            color   : error ? colors.error : colors.text,
        },
        border : line  ? "1px solid " + colors.grey03    : "none",
        focus:{
            borderColor: error ? colors.error :  "#5FA631" ,
        },
        caret: error ? colors.error: "#5FA631"
    };

    return (
        <Container
            className={className}
            flex={flex}
            theme={theme}
            style={style}
            mw={mw}
        >
            <label>{label} {error && <i className="icon icon-info-circle"> </i>}</label>
            {children ?
             <div className={"wc position-relative"}>
                 {(error || select) &&
                 <Flex flex={"0 0 20px"} className={"error-x"} style={{color:"red"}}>
                     { error ? <i className="icon-cancel"> </i> :
                         ( select ?
                             <div className={"mr-3"}>
                                 <i className="icon-caret" style={{fontWeight:"bold"}}> </i>
                             </div>
                             : "")
                     }
                 </Flex>
                 }
                 {children}
             </div>

                :
                <Flex className={"wc"} direction={"row"}>

                    {(error || select) &&
                    <Flex flex={"0 0 20px"} className={"error-x "} style={{color:"red"}}>
                        { error ? <i className="icon-cancel"> </i> :
                            ( select ?
                                <div className={" pt-3 pr-2 "}>
                                    <i className="icon-caret" style={{fontWeight:"bold"}}> </i>
                                </div>
                                : "")
                        }
                    </Flex>
                    }


                    {before &&
                    <Flex flex={"0 0 25px"} className={'before'} >
                        $
                    </Flex>
                    }

                    <Flex flex={"1 0 70%"}>
                        {
                            Clve ?
                                <Cleave
                                    type        = {type}
                                    onChange    = { onChange}
                                    value       = {value }
                                    name        = {name }
                                    placeholder = { placeholder}
                                    disabled    = {disabled}
                                    options     = {ClveOptions}
                                />:
                                <input
                                    type        = {type}
                                    onChange    = { onChange}
                                    value       = {value || "" }
                                    name        = {name }
                                    placeholder = { placeholder}
                                    disabled    = {disabled}
                                    options     = {ClveOptions}
                                />
                        }
                    </Flex>
                </Flex>
            }

            <Collapse isOpened={error} className={"wc"}>
                <p className={"error"}>{error}</p>
            </Collapse>
        </Container>
    )
}

export default React.memo(Field);
