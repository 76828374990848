import React, {useEffect, useState, memo} from "react";
import styled from "@emotion/styled";
import Flex from "../../UI/Flex";
import Btn from "../../UI/Btn";
import {ItemData, BtnIcon} from "./helper";
import Good from "./../../img/img_feedback_manoarriba.png";
import bad from "./../../img/img_feedback_manoabajo.png";
import Loading from "./../../UI/loading";
import Fade from "../../UI/Fade";
import {colors} from "../../UI";
import {numberWithCommas, getCookie, getToken} from "../../helper";
import {ThePdf} from "../PDF";
import axios from "axios";

const Container = styled.div`
    position:relative;
    
    .card{
        width:100%;
        max-width:400px;
        margin:auto;
        border-radius:15px;
        box-shadow:0 1px 5px rgba(0,0,0,.1);
    }
`;

function Response() {

    let [response, setResp] = useState({
        loading: true,
        idRequest: null,
    });

    const publiKey = process.env.REACT_APP_PUBLIC_KEY;

    let consultaPSE = async () => {

        let transactionID = getCookie("transactionID");
        let token = await getToken();

        let data = {
            "transactionID": transactionID
        };

        axios({
            data: data,
            url: ` ${process.env.REACT_APP_BASE_URL_REST_RECAUDO}/api/apify/payment/pse/transaction`,
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`
            }
        }).then(response => {
            const {documento, tipo_doc} = response.data.data;
            consult(documento, tipo_doc);
        }).catch(error => {
            consult();
        });
    };

    const consult = (docNumber = "", docType = "CC") => {

        let refPayco = getCookie("refPayco");
        let idRequest = getCookie("idRequest");

        let url = process.env.REACT_APP_SECURE + "?public_key=" + publiKey + "&ref_payco=" + refPayco;
        axios.get(url)
            .then(responseTransaction => {
                if (responseTransaction.status === 200 && responseTransaction.data.success) {
                    let data = responseTransaction.data.data;
                    setResp({
                        ...response,
                        amount: data.x_amount,
                        cus: data.x_approval_code,
                        bank: data.x_bank_name,
                        hola: data.x_cod_transaction_state,
                        currency: data.x_currency_code,
                        idClient: data.x_cust_id_cliente,
                        ip: data.x_customer_ip,
                        description: data.x_description,
                        date: data.x_fecha_transaccion,
                        franchise: data.x_franchise,
                        refPayco: data.x_ref_payco,
                        state: data.x_response,
                        trxId: data.x_transaction_id,
                        comision: getCookie("commission") || 0,
                        number: getCookie("number"),
                        loading: false,
                        fallida: data.x_response === "Fallida" || data.x_response === "Rechazada" || data.x_response === "Abandonada",
                        status: data.x_response === "Aceptada",
                        pendiente: data.x_response === "Pendiente",
                        docType,
                        docNumber,
                        idRequest
                    });
                } else {
                    setResp({...response, fallida: true, loading: false, pendiente: false})
                }
            }).catch(error => {

            setResp({...response, loading: false, status: false});

            setResp({...response, fallida: true, loading: false, pendiente: false});
            console.log(error);
        });
    };

    useEffect(() => {
        consultaPSE();
    }, []);

    return (
        <Container className={"py-3 px-2"}>
            {response.loading ?
                <div>
                    <Loading only noBg/>
                </div> :
                <Fade>
                    <div className="card pb-3">
                        <div className="title text-center py-3"
                             style={{color: response.status ? colors.greem : colors.error}}>
                            <h4 className={"mb-0 ek-Xbold"}> Transacción {response.status ? (response.pendiente ? "pendiente " : "exitosa") : "rechazada"} </h4>
                        </div>

                        <div className="wc mb-4 text-center">
                            <img src={response.status ? Good : bad} alt="" width={"100px"} height={"auto"}
                                 className={"mx-auto"}/>
                        </div>

                        {response.fallida ?
                            <div className={"wc"}>
                                <div className={"ek-bold text-center pb-3"} style={{color: "#5C5C5C"}}>
                                    Intenta nuevamente
                                </div>
                                <div>
                                    <Btn
                                        mw={"160px"}
                                        className={"mx-auto"}
                                        btntype={"secondary"}
                                        onClick={() => {
                                            window.location.href = "/";
                                        }}
                                    >
                                        Finalizar
                                    </Btn>
                                </div>
                            </div>
                            :
                            <>
                                {response.pendiente ?
                                    <div className={"col-11 mx-auto"}>
                                        <p className={"text-center cg"}>Estamos evaluando su solicitud. Le enviaremos un
                                            correo con la respuesta del pago aprobado o rechazado.</p>
                                    </div>
                                    :
                                    <>
                                        <div className="wc px-3">
                                            <ItemData name={"Número de celular"} value={response.number}/>
                                            <ItemData name={"Valor de la recarga"}
                                                      value={`$${response.amount && numberWithCommas(response.amount)}`}/>
                                            <ItemData name={"Fecha y hora de la transacción"} value={response.date}/>
                                            <ItemData name={"Costo de la transacción"}
                                                      value={`$${response.comision && numberWithCommas(response.comision)}`}/>
                                            <ItemData name={"Referencia"} value={response.refPayco}/>
                                        </div>

                                        {/*
                                        <Flex className={"wc px-3 d-none"}>
                                            <BtnIcon flex={"0 0 150px"} name={"Compartir"} icon={"share"}/>
                                            <ThePdf response={response}/>
                                        </Flex>
                                        */}
                                    </>}
                                <div className={"py-3 wc"}>
                                    <Btn
                                        mw={"160px"}
                                        className={"mx-auto"}
                                        onClick={() => {
                                            window.location.href = "/?recharge=" + response.idRequest;
                                        }}
                                    >
                                        Finalizar
                                    </Btn>
                                </div>
                            </>
                        }
                    </div>


                </Fade>
            }
        </Container>
    )
}

export default memo(Response);
